import React from 'react'
import { Typography, Box } from '@material-ui/core'

export default () => {
  return (
    <Box display='flex' justifyContent='center' alignItems='center'>
      <Typography>There was an Error</Typography>
    </Box>
  )
}
